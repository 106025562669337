import image_1 from './temp1.PNG'
import image_2 from './temp.PNG'
import image_3 from './temp7.PNG'
import image_4 from './temp8.PNG'

export class ProjectImages {
   '1' = image_1
   '2' = image_2
   '3' = image_3
   '4' = image_4

}
export default ProjectImages
