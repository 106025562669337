export default function AboutMe() {
    return (
      <div  id="section1">
        <p className="text-subtleText">
        Back in 2014 I decided I was going to
        pursure a career in Computer Science. 
        It was here at the University of Arizona my passion
        for software development was cultivated. Fast forward to today, 
        I&apos;ve had the privelage of building software
        for a <span>start-up</span>, <span>LLC</span>, <span>Fortune 50 company</span>, <span>big 5 firm</span>, and personal projects. 
        <br />
        <br />
        My main focus these days are backend infrastructure for our clients at <span>Deloitte</span>.
        I most enjoy the engineering challenge of maintaining software under the hood 
        where the magic of seamless user interaction happens.
        <br />
        <br />
        When I&apos;m not at the computer, I&apos;m usually mountain biking, snowboarding, 
        playing <span>video games</span> with my friends, or creating a tasty concoction in the kitchen.
      </p>
      </div>
    )
  }