import ProjectImages from '../assets/projects/ProjectImages';
import jsonData from '../assets/projects/Projects.json'
import {HiArrowUpRight} from 'react-icons/hi2'


export default function Projects() {

    var img = new ProjectImages()

    const frameworkItems = (frameworkArray) => {
        let id = 0;
        let items = frameworkArray.map(e => 
            <div key={id++} className="bg-frameworkBackground text-sm text-frameworkText px-3 py-1 m-2 rounded-full w-fit bg-opacity-20">
                {e}
            </div>
        )
        return items
    }
    
    const historyItems = jsonData["projects"].map(curr => 
        <div key={curr.id} className="flex group md:hover:shadow-inner md:hover:bg-gray-400 md:hover:bg-opacity-10 md:hover:shadow-lg rounded-lg my-5 md:p-5">
            <div className="text-subtleText italic md:text-center w-1/3 md:px-3 pr-3">
                <img src={img[curr.id]} alt="project img" className='w-[100px] h-[50px]' />
            </div>
            <div className="w-fit">
                <a href={curr.job.link} target="_blank" rel="noopener noreferrer">
                    <h2 className="md:group-hover:text-frameworkBackground hover:text-frameworkBackground flex group/title pb-2">
                        {curr.job.title}&nbsp;<HiArrowUpRight className="transform group-hover/title:-translate-y-1 group-hover/title:translate-x-1 md:group-hover:translate-x-1 md:group-hover:-translate-y-1"/>
                    </h2>
                </a>
                <p className="text-subtleText">
                    {curr.job.desc}
                </p>
                <div className="flex flex-wrap">
                    {frameworkItems(curr.job.framework)}
                </div>
            </div>
        </div>
    )

  return (
    <div id="section2">
        <h2>Projects</h2>
            <p className='pt-5 text-subtleText'>These following demo sites were created as a 
            personal investigation into website design frameworks and work flows.            
            </p>
        {historyItems}
    </div>
  )
}